import { Injectable, Inject } from '@angular/core';
import { TermService } from './terms/term.service';
import {
    AuthEsia,
    AuthOrg,
    UserRole,
    User,
    Organization,
    UIRole,
    EntData,
    TokenIntegration,
    UIPermission,
} from './srv.types';
import { HttpClient } from '@angular/common/http';
import { NavController, ToastController } from '@ionic/angular';
import { DOCUMENT } from '@angular/common';
import { SrvService } from './srv.service';
import { Subject, Observable, BehaviorSubject, from } from 'rxjs';
import { filter, debounceTime, take, map, catchError, mergeMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BidDateConfig, ConfigurationService } from './configuration.service';
import { TILE_SERVER_DEFAULT, LAYERS, COMMON_OPTIONS } from './const-dev';
import { MainPageListItem, Trigger } from './app.types';
import { RoleAlias } from './app.types';
import { ScopeType, UI_CLIENT, UI_ENTRANCE, UI_SCOPE, UI_TERMS, UI_VISUALS } from './app.const';
import { UntypedFormGroup } from '@angular/forms';
import { AListPaginator } from './ws/alist/alist-paginator';
import { EXTRA_PERMISSIONS } from 'src/const';
import { EntKey } from './ws/model/ws.model';

export interface IAppLayer {
    form?: UntypedFormGroup;
    entity?: EntData;
    modal?: any;
    type?: 'entity' | 'list' | 'preview';
    label?: string;
    icon?: string;
    selected?: boolean;
    paginator?: AListPaginator;
    focused?: boolean;
}

/** Определяет визуальный стиль страницы входа. #stage если на тестовом полигоне */
export type VisualCode = '#stage' | '#opvk';

@Injectable({
    providedIn: 'root',
})
export class AppService {
    visualCode: VisualCode = '#opvk';
    visualStyle$: BehaviorSubject<any> = new BehaviorSubject(this.visualStyle);
    visualEntranceStyle$: BehaviorSubject<any> = new BehaviorSubject(this.visualEntranceStyle);
    _counter = 0;

    isModalView = false;
    isLogged = false;
    uiIsDark = false;
    defaultWsPage = '/ws/databoard';
    profile = null;
    token_integration: TokenIntegration = null;
    token_jwt: string = null;
    user = null;
    roles: UserRole[] = null;
    public currentRole: UserRole = null;
    role: UIRole = null;
    licenses: any[] = null;
    organization = null;
    mainPagesList: MainPageListItem[] = null;
    mainErrorMessage = null;
    auth: AuthEsia = null;
    authFollowUrl: string = null; // По факту null

    trigger$: Subject<Trigger> = new Subject();

    //TODO: На первый взгляд кажется, что Субъекта вполне достаточно
    public layersSubject$ = new BehaviorSubject<IAppLayer[]>([]);
    layers$ = this.layersSubject$.asObservable();

    public isMapShown$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isGroupedListShown$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private LAYERS = {
        OPENSTREET: {
            key: 'OPENSTREET',
            title: '<span class="icon icon_map2"></span> OpenStreetMap',
            url: `https://{s}.${this.tileServer}/street/{z}/{x}/{y}.png`,
            options: COMMON_OPTIONS,
        },
        BLACK_WHITE: {
            key: 'BLACK_WHITE',
            title: '<span class="asuicon asuicon_blackandwhite"></span> Черно-белая OpenStreetMap',
            url: `https://{s}.${this.tileServer}/bw/{z}/{x}/{y}.png`,
            options: COMMON_OPTIONS,
        },
        VOLGA: {
            key: 'VOLGA',
            url: 'https://map.b3asu.ru/volga/{z}/{x}/{y}.png',
            title: '<span class="icon icon_panorama"></span> Берега Волги',
            options: {
                minNativeZoom: 8,
                maxNativeZoom: 21,
                maxZoom: 21,
                attribution: '&copy; <a href="">Volga</a>',
            },
        },
        TWOGIS: {
            key: 'TWOGIS',
            title: '<span class="icon icon_map"></span> 2ГИС',
            url: 'http://tile0.maps.2gis.com/tiles?x={x}&y={y}&z={z}&v=1',
            options: { COMMON_OPTIONS, attribution: '&copy; <a href="http://law.2gis.ru">2ГИС</a>' },
        },
    };

    public LAYERS_SETS = {
        entry: { default: LAYERS.BLACK_WHITE },
        modal: {
            default: this.LAYERS.OPENSTREET,
            twoGIS: LAYERS.TWOGIS,
        },
        back: {
            default: this.LAYERS.OPENSTREET,
            openStreetMapBlackAndWhite: this.LAYERS.BLACK_WHITE,
            satellite: LAYERS.SATELLITE,
            twoGIS: LAYERS.TWOGIS,
            light: LAYERS.LIGHT,
            dark: LAYERS.DARK,
            toner: LAYERS.TONER,
            traffic: LAYERS.TRAFFIC,
        },
        overlay: {
            grid_2: LAYERS.GRID2,
            grid_10: LAYERS.GRID10,
            volga: LAYERS.VOLGA,
            cadastre: LAYERS.CADASTRE,
            zones: LAYERS.ZONES,
            gps: LAYERS.GPS,
        },
        common: {},
    };

    public LAYERS_SETS_OPENMAP = {
        entry: { default: LAYERS.BLACK_WHITE },
        modal: {
            default: this.LAYERS.TWOGIS,
        },
        back: {
            default: this.LAYERS.TWOGIS,
            openStreetMapBlackAndWhite: this.LAYERS.BLACK_WHITE,
            satellite: LAYERS.SATELLITE,
            light: LAYERS.LIGHT,
            dark: LAYERS.DARK,
            toner: LAYERS.TONER,
            traffic: LAYERS.TRAFFIC,
        },
        overlay: {
            grid_10: LAYERS.GRID10,
            volga: LAYERS.VOLGA,
            cadastre: LAYERS.CADASTRE,
            zones: LAYERS.ZONES,
            gps: LAYERS.GPS,
        },
        common: {},
    };

    private _currentRoleCode: string = '';

    constructor(
        private termSe: TermService,
        private http: HttpClient,
        private nav: NavController,
        private toast: ToastController,
        private srv: SrvService,
        private router: Router,
        @Inject(DOCUMENT) readonly document: Document,
        private appConfig: ConfigurationService,
    ) {
        // @ts-ignore
        if (window) window.state = this;

        if (window?.location?.hostname && window.location.hostname.includes('opvk.tech')) {
            this.visualCode = '#stage';
        }

        this.srv.getTerm = this.termSe.getTerm.bind(this.termSe);
    }

    get visualStyle(): any {
        return this.appConfig.visualStyle || UI_VISUALS[this.visualCode];
    }

    get visualStyles(): string[] {
        return Object.keys(UI_VISUALS);
    }

    get visualEntranceStyle(): any {
        if (this.visualCode === '#stage') return UI_ENTRANCE[this.visualCode]; // Стэйдж полигон. Игнорируем конфиг entrance
        return this.appConfig.visualEntranceStyle || UI_ENTRANCE[this.visualCode];
    }

    get visualEntranceStyles(): string[] {
        return Object.keys(UI_VISUALS);
    }

    get tileServer(): any {
        return this.appConfig.tileServer && this.appConfig.tileServer !== TILE_SERVER_DEFAULT
            ? this.appConfig.tileServer
            : TILE_SERVER_DEFAULT;
    }

    get bidDateConfig(): BidDateConfig {
        return this.appConfig.bidDateConfig;
    }

    get window(): Window {
        return this.document.defaultView;
    }

    get currentRoleName(): string {
        return (this.currentRole && this.currentRole.name) || 'Без роли';
    }

    get currentRoleCode(): string {
        let now = new Date().getTime();
        let currentRoleCode = this._currentRoleCode || '';
        if (localStorage.getItem('role')) {
            let ls = JSON.parse(localStorage.getItem('role'));
            return now - Number(ls.timestamp) < 86400000 ? ls.value : currentRoleCode;
        } else return currentRoleCode;
    }

    setVisualStyle(code: VisualCode) {
        // Не используется?
        this.visualCode = code;
        this.visualStyle$.next(this.visualStyle);
    }

    setVisualEntranceStyle(code: VisualCode) {
        this.visualCode = code;
        this.visualEntranceStyle$.next(this.visualEntranceStyle);
    }

    toggleMap() {
        this.isMapShown$.next(!this.isMapShown$.value);
    }

    toggleGroupedList() {
        this.isGroupedListShown$.next(!this.isGroupedListShown$.value);
    }

    did(actionKey: Trigger | Trigger[]) {
        if (actionKey instanceof Array) [...actionKey].forEach((key) => this.trigger$.next(key));
        else this.trigger$.next(actionKey);
    }

    triggerOn$(keys: Set<Trigger>, bounceTime: number = 200): Observable<any> {
        return this.trigger$.pipe(
            filter((key) => keys.has(key)),
            debounceTime(bounceTime),
        );
    }

    public selectOrg(org: AuthOrg) {
        if (!this.auth || !this.auth.data) return;
        if (this.auth.data.state === 'wait') {
            this.redirect(this.auth.data.url_follow.replace('/*/', `/${org.oid}/`));
        }
    }

    makeProfile(user: User, org: Organization, roles: UserRole[]) {
        let profile = {
            name: user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : user.username,
            orgname:
                user._organization_info && user._organization_info.__str__
                    ? user._organization_info.__str__
                    : user._organization_info_last && user._organization_info_last.__str__
                      ? user._organization_info_last.__str__
                      : null,
            organizationInfoId: user.organization_info_id,
            organizationRootId: user.organization ? user.organization.id : null,
            profilePic: 'assets/img/default-avatar.png',
            id: user.id,
            mainRole: 'full',
            vars: {
                'user.modeling_host': user.modeling_host,
                'user.telemetry_host': user.telemetry_host,
                'user.session_id': user.session_id,
            },
            orgGeoPoint: user._organization && user._organization.point ? user._organization.point : null,
            user_profile: user.profile,
            _vicarious: user._vicarious,
        };
        this.profile = profile;
        this.roles = roles;
        this.organization = org || {};
        this.remakeProfile();
        this.srv.ids.user_profile = this.profile.user_profile?.id;
        this.srv.ids.organization_info = this.profile.organizationInfoId;
        this.srv.profile = this.profile;
    }

    remakeProfile() {
        this.profile.roles = [];
        this.roles.forEach((srvRole) => {
            this.profile.roles.push({
                code: `role#${srvRole.id}`,
                isMain: srvRole.is_default,
                ...srvRole,
            });
        });
        const mainRole = this.profile.roles.find(
            (role) =>
                (role.isMain && this.hasUiItem('ui.client.workspace-wa', 'client', role)) ||
                this.hasUiItem('ui.client.workspace-wa', 'client', role),
        );

        const roleCode = this.profile.roles.find(
            (role) => role.code === this.currentRoleCode && this.hasUiItem('ui.client.workspace-wa', 'client', role),
        )
            ? this.currentRoleCode
            : (mainRole && mainRole.code) || this.profile.mainRole || this.profile.roles[0].code;
        if (roleCode) this.adjustRole(roleCode);
    }

    adjustRole(roleCode, thenCheckDefaultPage = false) {
        let role = this.profile.roles.find((role) => role.code === roleCode);

        this.currentRole = role;
        // console.log('Select ui role', roleCode, uiRole);
        this.mainPagesList = [];
        this.termSe.currentOverride = role.override;
        role.scope
            .filter((rule) => rule && rule.indexOf('ui.page.') === 0 && rule.split('.').length === 3)
            .forEach((pageRule) => {
                let pageCode = pageRule.slice(8); // ui.page.bid => bid
                let externalLink = this.termSe.getTerm(`ui.page.${pageCode}.externalLinkUri`);
                let filterPresets: string | string[] = this.termSe.getTerm(`ui.page.${pageCode}.filter_presets`);
                if (typeof filterPresets === 'string') filterPresets = filterPresets.split(',');
                let menuItem: MainPageListItem = {
                    title: this.termSe.getTerm(`ui.page.${pageCode}.title`) || pageCode,
                    url: externalLink || `/ws/${pageCode}`,
                    icon: this.termSe.getTerm(`ui.page.${pageCode}.icon`) || 'apps',
                    isExternalLink: !!externalLink,
                    externalLinkTarget: '_blank', // || this.termSe.getTerm(`ui.page.${pageCode}.externalLinkTarget`), // Закомментировано потому что всегда первый вариант. Зачем был второй непонятно
                };
                let needOpenInSignMenu = false;
                if (this.termSe.getTerm(`ui.page.${pageCode}.multisign`)) {
                    const multisignType: EntKey = this.termSe.getTerm(`ui.page.${pageCode}.multisign`) as EntKey;
                    menuItem._isOpenedInSidemenu = false;
                    menuItem.signCartPage = {
                        type: multisignType,
                        goto: () =>
                            this.router.navigate(['ws/multisign'], {
                                queryParams: { type: multisignType },
                                replaceUrl: true,
                            }),
                    };
                    needOpenInSignMenu = true;
                }
                if (filterPresets && filterPresets instanceof Array) {
                    // пресет выглядит так: ui.page.bids-ws.filter_presets: bid-w-s_draft,bid-w-s_declined,bid-w-s_prepayment,bid-w-s_order-formed
                    menuItem._isOpenedInSidemenu = false;
                    menuItem.subPages = filterPresets.map((filterPresetCode) => {
                        let title: string = filterPresetCode;
                        return {
                            type: 'filter',
                            code: filterPresetCode,
                            title,
                            filter: {},
                            goto: (() => {
                                if (this.router.url.includes(menuItem.url)) {
                                    this.router.navigate([menuItem.url], {
                                        queryParams: {
                                            filterPresetCode,
                                        },
                                        queryParamsHandling: 'merge',
                                        replaceUrl: true,
                                    });
                                } else {
                                    this.router.navigate([menuItem.url], { replaceUrl: true }).finally(() => {
                                        this.router.navigate([menuItem.url], {
                                            queryParams: {
                                                filterPresetCode,
                                            },
                                            queryParamsHandling: 'merge',
                                            replaceUrl: true,
                                        });
                                    });
                                }
                            }).bind(this),
                        };
                    });
                    needOpenInSignMenu = true;
                }
                if (needOpenInSignMenu) {
                    // Надо учесть если нет страницы мультиподписания
                    menuItem.refreshSidemenuStats = () => {
                        if (menuItem.subPagesSbscrptn) menuItem.subPagesSbscrptn.unsubscribe();
                        const params = menuItem.signCartPage
                            ? {
                                  _filter_preset: filterPresets,
                                  object_type: menuItem.signCartPage.type,
                              }
                            : { _filter_preset: filterPresets };
                        menuItem.subPagesSbscrptn = this.srv
                            .fetchSomething$({
                                endpoint: 'filter_preset_aggs',
                                params,
                            })
                            .pipe(take(1))
                            .subscribe((data) => {
                                menuItem.subPagesSbscrptn = null;
                                if (menuItem.subPages)
                                    menuItem.subPages
                                        .filter((p) => p.type === 'filter')
                                        .forEach((subPage) => {
                                            subPage.count = data[subPage.code];
                                        });
                                if (menuItem.signCartPage) menuItem.signCartPage.count = data['sign_cart']['total'];
                            });
                    };
                    Object.defineProperty(menuItem, 'isOpenedInSidemenu', {
                        get: () => menuItem._isOpenedInSidemenu,
                        set: (isOpenedInSidemenu) => {
                            console.log('SET isOpenedInSidemenu', pageCode, isOpenedInSidemenu);
                            menuItem._isOpenedInSidemenu = isOpenedInSidemenu;
                            if (isOpenedInSidemenu) menuItem.refreshSidemenuStats();
                        },
                    });
                }

                if (menuItem.url === '/ws/dashboard') {
                    this.mainPagesList.unshift(menuItem);
                } else {
                    this.mainPagesList.push(menuItem);
                }
            });

        // console.warn("🚀 ~ file: app.service.ts:758 ~ AppService ~ adjustRole ~ this.mainPagesList:", this.mainPagesList)
        if (this._currentRoleCode !== roleCode) {
            let ls = { value: roleCode, timestamp: new Date().getTime() };
            localStorage.setItem('role', JSON.stringify(ls));
            this._currentRoleCode = roleCode;
        }

        this.defaultWsPage = role.mainPage;

        setTimeout(() => {
            this.did('role.init');
            if (thenCheckDefaultPage) this.gotoDefaultPageIfChanged();
        }, 0);
    }

    public hasUserRole(roleAlias: RoleAlias) {
        let rolesMap: Map<RoleAlias, number> = new Map([
            ['oo', 4],
            ['ot', 7],
            ['op', 8],
        ]);
        let index = this.profile.roles.findIndex((role) => +role.id === rolesMap.get(roleAlias));
        return index !== -1;
    }

    gotoDefaultPageIfChanged() {
        if (this.mainPagesList.every((item) => item.url !== this.router.url.slice(0, item.url.length))) {
            this.nav.navigateForward([this.defaultWsPage]);
        }
    }

    getDataRole$(role): Observable<any> {
        return this.srv.fetchOne$('role_ui', role.id).pipe(
            tap(this.addExtraPermissions),
            map((_role) => {
                const profileVars = {
                    'user.modeling_host': this.user.modeling_host,
                    'user.telemetry_host': this.user.telemetry_host,
                    'user.session_id': this.user.session_id,
                };
                const terms = _role.$makeup().$snapshot.permissions.map((term) => {
                    if (term.value && typeof term.value === 'string')
                        term.value = term.value.replace(/\{([^\{\}]*)\}/g, (m, varCode) =>
                            profileVars[varCode] !== undefined ? `${profileVars[varCode]}` : `UNDEFINED_${varCode}`,
                        );
                    return term;
                });
                const termsDct = terms.reduce((acc, item) => {
                    acc[item.slug] = item.value;
                    return acc;
                }, {});
                const uiRole = {
                    ...role,
                    mainPage: termsDct['mainPage'],
                    scope: terms.filter((term) => UI_SCOPE[term.slug]).map((term) => term.slug),
                    override: {
                        ...Object.keys(termsDct)
                            .filter(
                                (k) =>
                                    k.substring(0, 8) === 'ui.page.' ||
                                    k.substring(0, 7) === 'ui.ent.' ||
                                    ~k.indexOf('filter_preset'),
                            )
                            .reduce((acc, termSlug) => {
                                acc[termSlug] = termsDct[termSlug];
                                return acc;
                            }, {}),
                        ...UI_TERMS.reduce((acc, term) => {
                            if (termsDct[term.slug]) acc[term.slug] = termsDct[term.slug];
                            return acc;
                        }, {}),
                    },
                    client: terms.filter((term) => UI_CLIENT[term.slug]).map((term) => term.slug),
                };
                return uiRole;
            }),
        );
    }

    clearFilterPreset() {
        this.router.navigate([], {
            queryParams: {
                filterPresetCode: undefined,
            },
            queryParamsHandling: 'merge',
        });
    }

    hasSectionItem(sectionCode, itemCode) {
        if (!this.currentRole) return true;
        if (!this.currentRole.override) return true;
        if (!this.currentRole.override[`ui.${sectionCode}.hides`]) return true;
        return !~this.currentRole.override[`ui.${sectionCode}.hides`].indexOf(itemCode);
    }

    hasScopeItem(scopeItemCode: string) {
        if (this.currentRole && this.currentRole.scope && ~this.currentRole.scope.indexOf(scopeItemCode as ScopeType))
            return true;
        else return false;
    }

    hasUiItem(itemCode, groupKey, role = this.currentRole) {
        console.log('🚀 ~ AppService ~ hasUiItem ~ itemCode:', itemCode, groupKey, role);
        if (role && role[groupKey] && ~role[groupKey].indexOf(itemCode)) return true;
        else return false;
    }

    isCurrentRole(roleCode): boolean {
        return this._currentRoleCode === roleCode;
    }

    public gotoEntrance() {
        this.nav.navigateRoot('/entrance');
    }

    public navto(path) {
        this.nav.navigateRoot(path);
    }

    public noteAuthError(err) {
        this.mainErrorMessage = `${err.text || err.code || err}`;
        this.toast
            .create({
                header: 'Доступ запрещен',
                message: this.mainErrorMessage,
                color: 'danger',
                position: 'middle',
                buttons: [
                    {
                        text: 'Ясно',
                        role: 'cancel',
                        handler: () => this.srv.toastPresented$.next(false),
                    },
                ],
            })
            .then((t) => {
                t.present();
                this.srv.toastPresented$.next(true);
            });
    }

    public noteAuthSuccess() {
        this.toast
            .create({
                message: 'Первичный доступ предоставлен',
                duration: 2000,
                color: 'success',
            })
            .then((t) => t.present());
    }

    public noteRoleMobileClient(nameRole: string) {
        this.toast
            .create({
                header: 'Роль не доступна',
                message: `
        Работа под ролью "${nameRole}" в ФГИС ОПВК возможна только в мобильном приложении.
            `,
                color: 'primary',
                position: 'middle',
                cssClass: 'nci-toast',
                buttons: [
                    {
                        text: 'Ясно',
                        role: 'cancel',
                        handler: () => this.srv.toastPresented$.next(false),
                    },
                ],
            })
            .then((t) => {
                t.present();
                this.srv.toastPresented$.next(true);
            });
    }

    public redirect(url: string, target = '_self'): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            try {
                resolve(!!this.window.open(url, target));
            } catch (e) {
                reject(e);
            }
        });
    }

    public noteError(header: string, message: string) {
        this.toast
            .create({
                header,
                message,
                color: 'danger',
                position: 'top',
                buttons: [
                    {
                        text: 'Ясно',
                        role: 'cancel',
                        handler: () => this.srv.toastPresented$.next(false),
                    },
                ],
            })
            .then((t) => {
                t.present();
                this.srv.toastPresented$.next(true);
            });
    }

    public setLayers(layers: IAppLayer[]) {
        this.layersSubject$.next([...layers]);
    }

    public addLayer(newLayer: IAppLayer) {
        newLayer.selected = true;
        const layers = [...this.layersSubject$.getValue()];
        layers.forEach((l) => (l.selected = false));
        layers.push(newLayer);
        this.layersSubject$.next(layers);
    }

    public removeLayer(layer: IAppLayer) {
        const layers = [...this.layersSubject$.getValue()];
        let layerId = layers.indexOf(layer);
        if (~layerId) layers.splice(layerId, 1);
        this.layersSubject$.next(layers);
    }

    public findEntLayer(entkey, entid): IAppLayer {
        const layers = [...this.layersSubject$.getValue()];
        return layers.find(
            (layer) =>
                layer.type === 'entity' &&
                layer.entity &&
                layer.entity.type === entkey &&
                layer.entity.id === String(entid),
        );
    }

    public tryToStepBackToLayer(layer: IAppLayer) {
        let layers = this.layersSubject$.value;
        let topLayerId = layers.length - 1;
        let layerId = layers.indexOf(layer);
        if (~layerId && topLayerId > layerId) this.tryToCloseLayer(topLayerId, layerId, layers);
        layer.selected = true;
    }

    public updateIntegrationToken$(): Observable<TokenIntegration> {
        return this.http.post<TokenIntegration>('/webapi/v1/token_integration_refresh/', {}).pipe(
            map((response) => response['data'] ?? null),
            catchError((e) => {
                console.log('[ERROR]', e);
                let message = 'Сервис недоступен';
                if (e.error) {
                    if (e.error.errors) {
                        message = e.error.errors.reduce(
                            (acc, v) =>
                                `${acc ? acc + '; ' : ''}${v.status ? v.status + ': ' : ''}${v.detail || v.code || ''}`,
                            '',
                        );
                    }
                }
                return from(
                    this.toast
                        .create({
                            header: 'Ошибка доступа',
                            message: `${message}`,
                            color: 'danger',
                            position: 'middle',
                            buttons: [
                                {
                                    text: 'Ясно',
                                    role: 'cancel',
                                },
                            ],
                        })
                        .then((t) => t.present())
                        .then(() => {
                            throw e;
                        }),
                );
            }),
        );
    }

    public subscribeEntityTrigger(
        entkey: string,
        getEnt$: Observable<EntData>,
        bsub$: BehaviorSubject<any>,
        finalizer?: (ent: any) => any,
    ) {
        this.triggerOn$(new Set([entkey]))
            .pipe(mergeMap(() => getEnt$))
            .subscribe((entity) => {
                bsub$.next(finalizer ? finalizer(entity) : entity);
            });
    }

    private tryToCloseLayer(layerId: number, selectedLayerId: number, layers: IAppLayer[]) {
        let layer = layers[layerId];
        if (layer.modal && layer.modal.componentProps && layer.modal.componentProps)
            layer.modal.componentProps.closer(() => {
                layers.splice(layerId, 1);
                if (layerId - 1 > selectedLayerId) this.tryToCloseLayer(layerId - 1, selectedLayerId, layers);
            });
    }

    /** Если в const.ts (а в деве в const.dev.ts) заданы дополнительные разрешения, то добавляет их в ответ ролей
     *  Используется для тестовой отладки, когда нужные разрешения не заданы
     *  Работает только внутри ответа роли, возможно, надо туда перенести.
     */
    private addExtraPermissions(response) {
        const addPermissionsToResponse = (response: any, permissions: UIPermission[]) => {
            response.meta.permissions = response.meta.permissions.concat(permissions);
        };
        if (EXTRA_PERMISSIONS) addPermissionsToResponse(response, EXTRA_PERMISSIONS);
    }
}
